class ProductService {
  async fetchProductCategories() {
    try {
      const response = await fetch(
        'https://liebigs.hitechanimation.com/api/product_category/lists/v1/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status: 'ACTIVE',
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        //console.log('product category', data);
        return data; // Assuming the API response has a "data" property
      } else {
        throw new Error('Failed to fetch product categories');
      }
    } catch (error) {
      throw new Error('Failed to fetch product categories');
    }
  }

  async fetchProductsByCategory(categorykey) {

    //const categorykey = categorykey;

    //const replaced_category_name = category_name.replace(/\s+/g, "-");

    try {
      const response = await fetch('https://liebigs.hitechanimation.com/api/products/lists/v1/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          categorykey: categorykey,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('products',data);
        return data.data;
      } else {
        throw new Error('Failed to fetch products');
      }
    } catch (error) {
      throw new Error('Failed to fetch products');
    }
  }

  async fetchProductDetails(product_id) {

    //const categorykey = categorykey;

    //const replaced_category_name = category_name.replace(/\s+/g, "-");

    try {
      const response = await fetch('https://liebigs.hitechanimation.com/api/products/details/v1/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: product_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('products',data);
        return data.data;
      } else {
        throw new Error('Failed to fetch product details');
      }
    } catch (error) {
      throw new Error('Failed to fetch product details');
    }
  }

  async fetchProductsAll() {

    //const categorykey = categorykey;

    //const replaced_category_name = category_name.replace(/\s+/g, "-");
    const product_id = '123';
    try {
      const response = await fetch('https://liebigs.hitechanimation.com/api/products/listall/v1/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: product_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('products',data);
        return data.data;
      } else {
        throw new Error('Failed to fetch product details');
      }
    } catch (error) {
      throw new Error('Failed to fetch product details');
    }
  }

  async fetchTopProductsAll() {

    //const categorykey = categorykey;

    //const replaced_category_name = category_name.replace(/\s+/g, "-");
    const product_id = '123';
    try {
      const response = await fetch('https://liebigs.hitechanimation.com/api/products/topproductlistall/v1/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_id: product_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('topproducts',data);
        return data.data;
      } else {
        throw new Error('Failed to fetch top product details');
      }
    } catch (error) {
      throw new Error('Failed to fetch top product details');
    }
  }

}

export default ProductService;


