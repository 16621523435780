import React, { useEffect, useState } from 'react';
import atm1 from '../assets/icons/paypal.png';
import atm2 from '../assets/icons/master-card.png';
import atm3 from '../assets/icons/card.png';
import atm4 from '../assets/icons/visa.png';

import { AiFillLock, AiOutlineMail } from "react-icons/ai";
import { NavLink } from 'react-router-dom';
import {  GiFertilizerBag } from 'react-icons/gi';
import { FaFacebook,  FaGoogle, FaInstagram, FaHome, FaPhone } from 'react-icons/fa';

import ProductService from '../services/ProductService';

function Footer() {

  // State to store the product categories
  const [categories, setCategories] = useState([]);
  const [topproducts, setTopProducts] = useState([]);
  const [topProductsLoaded, setTopProductsLoaded] = useState(false);

  // Fetch product categories on component mount
  useEffect(() => {
    const productService = new ProductService();

    productService
      .fetchProductCategories()
      .then((data) => {
        setCategories(data.data); // Assuming the API response has a "data" property
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  useEffect(() => {
    const productService = new ProductService();

    productService
      .fetchTopProductsAll()
      .then((data) => {
        setTopProducts(data); // Assuming the API response has a "data" property
        setTopProductsLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  //console.log('topproducts',topproducts)
    return (
        <>
            <section className='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div>
                                <h3>About us</h3>
                                <p>We at Liebigs Agro Chem understand that each plant has different requirements during
                                    its life cycle. Our broad selection of innovative approaches is designed to meet the
                                    specific needs of different plants.</p>
                                <ul className='atmcard-icon'>
                                    <li>
                                        <img src={atm1} alt="atm-icon" />
                                    </li>
                                    <li>
                                        <img src={atm2} alt="atm-icon" />
                                    </li>
                                    <li>
                                        <img src={atm3} alt="atm-icon" />
                                    </li>
                                    <li>
                                        <img src={atm4} alt="atm-icon" />
                                    </li>
                                </ul>
                                <p><AiFillLock /> Secure Online Payment</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div>
                                <h3>Top Products</h3>
                                {topProductsLoaded ? (
                                    <ul className='footer_list_item'>
                                        {topproducts.map((prodct) => (
                                            <li key={prodct.id}>
                                            <NavLink to={`/product-info/${prodct.id}`}>
                                                {/* Assuming you have icons imported */}
                                                
                                                <h6><GiFertilizerBag /> {prodct.product_name}</h6>
                                            </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No top products found!</p>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div>
                                <h3>Our Service</h3>
                                <ul className='footer_list_item'>
                                    {categories.map((category) => (
                                        <li key={category.categorykey}>
                                        <NavLink to={`/products/${category.categorykey}`}>
                                            {/* Assuming you have icons imported */}
                                            
                                            <h6><GiFertilizerBag /> {category.category}</h6>
                                        </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <h3>Contact Us</h3>
                                <ul className='footer_contact_item'>
                                    <li>
                                        <b>We love to hear from you.</b>
                                    </li>
                                    <li style={{display:'flex',alignItems:'flex-start',gap:'10px'}}>
                                       <FaHome style={{fontSize:'30px'}}/><p className='mb-0'>3A, Dharmadas Row Landmark – Near Shitala Mandir Kolkata – 700026</p>
                                    </li>
                                    <li style={{display:'flex',alignItems:'flex-start',gap:'10px'}}>
                                       <FaPhone style={{fontSize:'30px', transform:'rotate(90deg)'}} /> <p className='mb-0'>Customer Care - 8100503421/ 033-24658334/ 033-24658616
                                       Bulk Product Enquiry (only)-8336969334</p>
                                    </li>
                                    <li style={{display:'flex',alignItems:'center',gap:'10px'}}>
                                        <AiOutlineMail style={{fontSize:'20px'}}/>
                                        <NavLink to='mailto:inquiry@liebigsagrochem.com'>
                                            inquiry@liebigsagrochem.com
                                        </NavLink>
                                    </li>
                                    <li className='social_icons'>
                                        <NavLink to='https://www.facebook.com/liebigs/?ref=bookmarks' target='_blank'>
                                            <FaFacebook />
                                        </NavLink>
                                        <NavLink to='https://maps.app.goo.gl/M39A2v6jdY1jHwT17' target='_blank'>
                                            <FaGoogle />
                                        </NavLink>
                                        <NavLink to='https://www.instagram.com/liebigsagrochempvt/' target='_blank'> 
                                            <FaInstagram />
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer