class CartService {
    
    async addtocart(productData) {
  
      //const categorykey = categorykey;
  
      //const replaced_category_name = category_name.replace(/\s+/g, "-");
      //console.log('userData',userData);

      try {
        const response = await fetch('https://liebigs.hitechanimation.com/api/cart/add/v1/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(productData),
        });
  
        if (response.ok) {
            const data = await response.json();
            // Assuming your API returns data with a success flag and any other relevant information
            //console.log(data)
            return data;
            // if (data.success) {
            //   return data; // Handle the response data as needed
            // } else {
            //   throw new Error(data.message || 'Failed to signup'); // You can customize the error message
            // }
          } else {
            throw new Error('Failed to fetch details');
          }
      } catch (error) {
        throw new Error('Failed to fetch details');
      }
    }

    async removeonetocart(productData) {
  
        //const categorykey = categorykey;
    
        //const replaced_category_name = category_name.replace(/\s+/g, "-");
        //console.log('userData',userData);
  
        try {
          const response = await fetch('https://liebigs.hitechanimation.com/api/cart/removeone/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(productData),
          });
    
          if (response.ok) {
              const data = await response.json();
              // Assuming your API returns data with a success flag and any other relevant information
              //console.log(data)
              return data;
              // if (data.success) {
              //   return data; // Handle the response data as needed
              // } else {
              //   throw new Error(data.message || 'Failed to signup'); // You can customize the error message
              // }
            } else {
              throw new Error('Failed to fetch details');
            }
        } catch (error) {
          throw new Error('Failed to fetch details');
        }
      }

    async deletetocart(productData) {
  
        //const categorykey = categorykey;
    
        //const replaced_category_name = category_name.replace(/\s+/g, "-");
        //console.log('userData',userData);
  
        try {
          const response = await fetch('https://liebigs.hitechanimation.com/api/cart/remove/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(productData),
          });
    
          if (response.ok) {
              const data = await response.json();
              // Assuming your API returns data with a success flag and any other relevant information
              //console.log(data)
              return data;
              // if (data.success) {
              //   return data; // Handle the response data as needed
              // } else {
              //   throw new Error(data.message || 'Failed to signup'); // You can customize the error message
              // }
            } else {
              throw new Error('Failed to fetch details');
            }
        } catch (error) {
          throw new Error('Failed to fetch details');
        }
    }

    async cartlist(userData) {
  
      //const categorykey = categorykey;
  
      //const replaced_category_name = category_name.replace(/\s+/g, "-");
      //console.log('userData',userData);

      try {
        const response = await fetch('https://liebigs.hitechanimation.com/api/cart/lists/v1/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });
  
        if (response.ok) {
            const data = await response.json();
            // Assuming your API returns data with a success flag and any other relevant information
            //console.log(data)
            return data;
            // if (data.success) {
            //   return data; // Handle the response data as needed
            // } else {
            //   throw new Error(data.message || 'Failed to signup'); // You can customize the error message
            // }
          } else {
            throw new Error('Failed to fetch details');
          }
      } catch (error) {
        throw new Error('Failed to fetch details');
      }
    }
  
  }
  
  export default CartService;
  
  
  