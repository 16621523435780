class LogoutService {
    
    async logout(sessionKey,userId) {
  
      //const categorykey = categorykey;
  
      //const replaced_category_name = category_name.replace(/\s+/g, "-");
      //console.log('userData',userData);
      console.log('sessionKey',sessionKey)

      //const sessionKeyString = JSON.stringify(sessionKey);

      try {
        const response = await fetch('https://liebigs.hitechanimation.com/api/logout/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'sessionkey': {sessionKey}, // Set the session_key in the Authorization header
            },
            body: JSON.stringify({ user_id: userId, sessionkey: sessionKey }),
        });
  
        if (response.ok) {
            const data = await response.json();
            // Assuming your API returns data with a success flag and any other relevant information
            //console.log(data)
            return data;
            // if (data.success) {
            //   return data; // Handle the response data as needed
            // } else {
            //   throw new Error(data.message || 'Failed to signup'); // You can customize the error message
            // }
          } else {
            throw new Error('Failed to fetch details');
          }
      } catch (error) {
        throw new Error('Failed to fetch details');
      }
    }
  
  }
  
  export default LogoutService;
  
  
  